import create from 'zustand';
import { useMutation, useQuery } from 'react-query';

interface VideoProgressState {
    videoProgress: Record<string, { progress: number; totalLength: number }>;
    loading: boolean;
    setVideoProgress: (params: { videoId: string, progress: number, totalLength: number }) => void;
    setLoading: (loading: boolean) => void;
}

interface VideoProgress {
    progress: number;
    totalLength: number;
    video: string;
}

interface VideoProgressParams {
    videoId: string;
    channelId: string;
    courseId: string;
    progress: number;
    totalLength: number;
}

const updateVideoProgress = async ({ videoId, channelId, courseId, progress, totalLength }: VideoProgressParams) => {
    const response = await fetch(`/api/videos/track-progress/${channelId}/${courseId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoId, progress, totalLength }),
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Error updating video progress');
    }

    return response.json();
};

const useZustandStore = create<VideoProgressState>((set) => ({
    loading: true,
    setLoading: (loading) => set({ loading }),
    videoProgress: {},
    setVideoProgress: ({ videoId, progress, totalLength }) => {
        set((state) => ({
            videoProgress: {
                ...state.videoProgress,
                [videoId]: { progress, totalLength },
            },
        }));
    }
}));

const fetchVideoProgress = async (channelId: string, courseId: string): Promise<Array<VideoProgress>> => {
    const response = await fetch(`/api/videos/progress/${channelId}/${courseId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Error fetching video progress');
    }
    return response.json();
};

const useVideoProgressStore = (params: { channelId: string, courseId: string }) => {
    const { channelId, courseId } = params;

    const {
        setVideoProgress,
        setLoading,
        loading,
        videoProgress
    } = useZustandStore((state) => ({
        setVideoProgress: state.setVideoProgress,
        setLoading: state.setLoading,
        videoProgress: state.videoProgress,
        loading: state.loading,
    }));

    useQuery(['videoProgress', channelId, courseId], async () => {
        const items = await fetchVideoProgress(channelId, courseId);
        items.forEach((item) => {
            const { video, progress, totalLength } = item;
            setVideoProgress({ videoId: video, progress, totalLength });
        });
    }, {
        onSettled: () => setLoading(false),
        onSuccess: () => setLoading(false),
    });

    // Sync with the server
    const { mutate } = useMutation(updateVideoProgress);

    function setVideoProgressWrapper (params: {
        videoId: string;
        progress: number;
        totalLength: number
    }) {
        const {videoId, progress, totalLength} = params;
        mutate({videoId, progress, totalLength, channelId, courseId});
        setVideoProgress({videoId, progress, totalLength});
    }

    return {
        videoProgress,
        setVideoProgress: setVideoProgressWrapper,
        loading
    };
};

export default useVideoProgressStore;