import React from 'react';
import DOMPurify from 'dompurify';
import styles from './content-description.module.css';
import Content from './types/content';

const ContentDescription: React.FC<{ content: Content }> = ({ content }) => {
    const sanitizedDescription = DOMPurify.sanitize(content.description);

    return (
        <div className={styles.description}>
            <h1 className={styles.headerText}>{content.title}</h1>
            {content.description && (
                <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
            )}
        </div>
    );
};

export default ContentDescription;