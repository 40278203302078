import React from 'react';
import { Link } from 'react-router-dom';
import { ProgressBar, ListGroup } from 'react-bootstrap';
import { FileEarmarkPdf } from 'react-bootstrap-icons';
import useVideoProgressStore from './stores/video-progress-store';
import styles from './playlist-links.module.css';

interface ListItem {
    title: string;
    path: string;
    type: string;
    previewImage: string;
}

interface PlaylistLinkProps {
    title: string;
    path: string;
    previewImage: string;
    channel: string;
    course: string;
    index: number;
    progress: number;
    totalLength: number;
    highlight: boolean;
    type: string;
}

const PlaylistLink: React.FC<PlaylistLinkProps> = (props: PlaylistLinkProps) => {
    const {
        totalLength,
        title,
        path,
        channel,
        course,
        index,
        progress,
        highlight,
        previewImage,
        type,
    } = props;
    let completed = 0;
    if (totalLength && progress) {
        completed = (progress / totalLength) * 100;
    }

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Link to={`/player/${channel}/${course}/${path}`}
              id={`${channel}-${course}-${index}`}
              onClick={handleClick}>
            {type === 'pdf' &&
                <ListGroup.Item as="div" className={highlight ? `${styles.listItemWrapper} ${styles.highlighted}` : styles.listItemWrapper }>
                    <div>
                        <FileEarmarkPdf className={styles.pdfHeaderImage} />
                    </div>
                    <div className={styles.infoLabel}>
                        <div className={styles.title}>{title}</div>
                    </div>
                </ListGroup.Item>
            }
            {type !== 'pdf' && previewImage &&
                <ListGroup.Item as="div" className={highlight ? `${styles.listItemWrapper} ${styles.highlighted}` : styles.listItemWrapper }>
                    <div className={styles.playlistHeaderImageWrapper} style={{
                        backgroundImage: `url(/course-info/${channel}/${course}/headers/${previewImage}/${previewImage}-640.jpg)`
                    }}>
                        <img className={styles.playlistHeaderImage}
                             src={`/course-info/${channel}/${course}/headers/${previewImage}/${previewImage}-640.jpg`}
                             alt={title} />
                        <ProgressBar className={styles.progressBar} now={completed} variant="info" visuallyHidden/>
                    </div>
                    <div className={styles.infoLabel}>
                        <div className={styles.title}>{title}</div>
                    </div>
                </ListGroup.Item>
            }
            {!previewImage && type !== 'pdf' &&
                <ListGroup.Item as="div" className={highlight ? styles.highlighted : ''}>
                    <div className={styles.noHeaderTitle}>{title}</div>
                    <ProgressBar now={completed} variant="info" visuallyHidden />
                </ListGroup.Item>
            }

            <br/>
        </Link>
    );
};

interface PlaylistLinksProps {
    links: Array<ListItem>;
    channelId: string;
    courseId: string;
    videoId: string;
    title: string;
}

const PlaylistLinks: React.FC<PlaylistLinksProps> = ({
                                                         links,
                                                         title,
                                                         channelId,
                                                         courseId,
                                                         videoId }) => {
    const {videoProgress} = useVideoProgressStore({channelId, courseId});
    return (
        <section className={styles.main}>
            <h1 className={styles.headerText}>{title}</h1>
            <ListGroup as="section" id="playlist-links" className={styles.listGroup}>
                {links.map((link, index) => {
                    const highlight = videoId === link.path;
                    const progressData = videoProgress[link.path] || { progress: 0, totalLength: 0 };
                    return (
                        <PlaylistLink
                            key={index}
                            {...link}
                            channel={channelId}
                            course={courseId}
                            index={index}
                            progress={progressData.progress}
                            totalLength={progressData.totalLength}
                            highlight={highlight}
                            previewImage={link.previewImage}
                            type={link.type}
                        />
                    );
                })}
            </ListGroup>
        </section>

    );
};

export default PlaylistLinks;