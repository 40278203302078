import {Button} from 'react-bootstrap';
import styles from "../home/home.module.css";

export default function CatalogHome() {
    return (
        <>
            <section style={{display: "flex", justifyContent: "center"}}>
                <div>
                    <h3 style={{color: "red"}}>Explore Channels</h3>
                    <ul className={styles.unstyledList}>
                        <li>
                            <Button className={styles.channelLogoButton}
                                    variant="outline-primary"
                                    href='/catalog/fiercevoiceacademy'>
                                <img className={styles.channelLogo} alt="Fierce Voice Academy Logo"
                                     src="/course-info/fiercevoiceacademy/channel-logo-light.png"/>
                            </Button>
                        </li>
                        <li>
                            <Button className={styles.channelLogoButton}
                                    variant="outline-primary"
                                    href={'/catalog/feedable'}>
                                <img className={styles.channelLogo}
                                     src="/images/feedable-logo-2024-crop.png"
                                     alt="Feedable Channel Logo"/>
                            </Button>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
}