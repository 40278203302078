import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';


// Add this function to save the last watched video
const saveLastWatchedVideo = async ({ channel, course, video }: { channel: string, course: string, video: string }) => {
    const response = await fetch(`/api/videos/last-watched`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ channel, course, video }),
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Error saving last watched video');
    }

    return response.json();
};

// Add this function to fetch the last watched video
const fetchLastWatchedVideo = async (channelId: string, courseId: string): Promise<{ video: string }> => {
    const response = await fetch(`/api/videos/last-watched/${channelId}/${courseId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Error fetching last watched video');
    }

    return response.json();
};

// Export the hooks
export const useSaveLastWatchedVideo = () => {
    return useMutation(saveLastWatchedVideo);
};

export const useGetLastWatchedVideo = () => {
    const { channelId, courseId } = useParams<{ channelId: string; courseId: string; }>();
    return useQuery(
        ['lastWatchedVideo', channelId, courseId],
        () => {
            if (channelId && courseId) {
                return fetchLastWatchedVideo(channelId, courseId);
            } else {
                return Promise.reject(new Error('Channel ID or Course ID is undefined'));
            }
        },
        {
            enabled: !!channelId && !!courseId, // Only run the query if channelId and courseId are defined
        }
    );
};