import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dropdown } from 'react-bootstrap';

import { useAuth } from '../AuthContext';
import { useContentCreatorChannels } from "~/hooks/use-content-creator-channels";

import styles from './login-button.module.css';

const LoginButton: React.FC = () => {
    const { login, logout, isAuthenticated, user, isLoading } = useAuth();
    const { data: channels } = useContentCreatorChannels();
    const navigate = useNavigate();

    const handleLoginLogout = async () => {
        if (isAuthenticated) {
            await logout();
        } else {
            login();
        }
    };

    return (
        !isLoading && (
            isAuthenticated ? (
                <Dropdown>
                    <Dropdown.Toggle style={{ display: "flex", alignItems: "center" }}
                                     variant="info"
                                     id="dropdown-basic">
                        <span className={styles.email}>{user?.firstName + " " + user?.lastName}</span>  <div className="caret"></div>
                    </Dropdown.Toggle>
                    <Dropdown.Divider />

                    <Dropdown.Menu>
                        {channels && channels.length !== 0 && (
                            <>
                                {channels.map((course) => (
                                    <Dropdown.Item key={course.channelId} onClick={() => navigate(`/videos/${course.channelId}/list`)}>
                                       Videos: {course.channelId}
                                    </Dropdown.Item>
                                ))}
                                <Dropdown.Divider />
                            </>
                        )}
                        {channels && channels.length !== 0 && (
                            <Dropdown.Item  onClick={()=> navigate('/upload')}>Upload</Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={handleLoginLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <Button onClick={handleLoginLogout}>
                    Login
                </Button>
            )
        ) || null
    );
};

export default LoginButton;