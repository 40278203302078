import { useQuery } from 'react-query';

interface User {
    id: string;
    name: string;
    email: string;
    firstName: string;
    lastName: string;
    // Add other user properties as needed
}

interface UserResponse {
    user: User;
    isAuthenticated: boolean;
}

const fetchUser = async (): Promise<UserResponse> => {
    const response = await fetch('/api/public/user', { credentials: 'include' });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export const useUserAuth = () => {
    return useQuery<UserResponse, Error>(['userAuthQuery'], fetchUser, {
        retry: false
    });
};