import React from 'react';
import ReactDOM from 'react-dom/client';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import './index.css';
import EmailPasswordLogin from './sign-in/email-password';
import EmailPasswordSignUp from './sign-up/email-password';
import VerifyEmailForm from './verify-email/verify-email-form';
import Home from './home/home';
import RequestReset from './reset-password/request-reset';
import ResetPassword from './reset-password/reset-password';
import UploadVideoForm from './upload-video/upload-video-form';
import ThankYou from './thank-you/thank-you';
import CoursePage from '~/course/course-page';
import CourseListPage from "./course-list/course-list-page";
import CourseBundlePage from "./course-list/course-bundle-page";
import {AuthLayout, RenewYouLayout, SimpleLayout} from "./layout";
import VideoList from "./video-list/video-list";
import ContactForm from "./contact-us/contact-form";
import CoursePlayer from "./course-player/course-player";
import {AuthProvider} from "./AuthContext";
import NotFound from './not-found/not-found';
import CatalogPage from "~/catalog/catalog";
import CatalogHome from "~/catalog/catalog-home";

const queryClient = new QueryClient();
const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const App = () => (
    <QueryClientProvider client={queryClient}>
        <React.StrictMode>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/player/:channelId/:courseId/:videoId?" element={<CoursePlayer/>}/>

                        <Route element={<RenewYouLayout mainStyle={{borderRadius: 0, width: "100%"}}/>}>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/catalog" element={<CatalogHome />}/>
                        </Route>

                        <Route element={<AuthLayout/>}>
                            <Route path="/thankyou" element={<ThankYou/>}/>
                            <Route path="/verify-email" element={<VerifyEmailForm/>}/>
                            <Route path="/request-password-reset" element={<RequestReset/>}/>
                            <Route path="/reset-password/:email" element={<ResetPassword/>}/>
                            <Route path="/contact-us" element={
                                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                                    <ContactForm/>
                                </GoogleReCaptchaProvider>
                            }/>
                            <Route path="/login" element={
                                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                                    <EmailPasswordLogin/>
                                </GoogleReCaptchaProvider>
                            }/>
                            <Route
                                path="/signup"
                                element={
                                    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                                        <EmailPasswordSignUp/>
                                    </GoogleReCaptchaProvider>
                                }/>
                        </Route>

                        <Route element={<SimpleLayout/>}>
                            <Route path="/upload" element={<UploadVideoForm/>}/>
                            <Route path="/catalog/:channelId" element={<CatalogPage/>}/>
                            <Route path="/videos/:channelId/list" element={<VideoList/>}/>
                            <Route path="/channel/:channelId/:courseId" element={<CourseBundlePage/>}/>
                            <Route path="/channel/:channelId" element={<CourseListPage/>}/>
                        </Route>

                        <Route element={<SimpleLayout linkToCatalog={true}/>}>
                            <Route path="/course/:channelId/:courseId/:discountCode?" element={<CoursePage/>}/>
                        </Route>


                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </React.StrictMode>
    </QueryClientProvider>
);

ReactDOM.createRoot(document.getElementById('root')!).render(<App/>);