import React from 'react';
import styles from "./main.module.css";
import {Row} from "react-bootstrap";
import ResponsiveImage from "./components/responsive-image";
import {Link, Outlet, useParams} from "react-router-dom";
import LoginButton from "./components/login-button";
import headerLogoStyles from '~/header-logo.module.css';

export const Footer = () =>
    <footer className={styles.footer}>
        <span className={styles.footer}>© 2024 Renew You</span>
        <Link to="/">Home</Link>
        <Link to="/contact-us">Contact Us</Link>
        <Link to="/catalog">Catalog</Link>
    </footer>;

export const AuthLayout = () => (
    <div>
        <section className={styles.authLayoutWrapper}>
            <Row>
                <header className={styles.logoWrapper}>
                    <Link to="/">
                        <ResponsiveImage className={styles.authLayoutLogo}
                                         alt="Renew You Logo"
                                         basePath="/images"
                                         src="renewyou-logo"
                        />
                    </Link>
                </header>
            </Row>
            <Row>
                <main className={styles.main}><Outlet/></main>
            </Row>
        </section>
        <Footer/>
    </div>
);

interface SimpleLayoutProps {
    headerText?: string;
    linkToCatalog?: boolean;
}

function getHeaderLink(channelId: string | undefined, linkToCatalog: boolean) {
    if (linkToCatalog && channelId) {
        return `/catalog/${channelId}`;
    } else {
        return `/`;
    }
}

export const SimpleLayout: React.FC<SimpleLayoutProps> = ({headerText, linkToCatalog}) => {
    const {channelId} = useParams<{ channelId: string }>();
    const headerLink = getHeaderLink(channelId, !!linkToCatalog);

    return <>
        <header className={styles.simpleLayoutHeader}>
            <nav className={styles.simpleLayoutHeaderTextWrapper}>
                {!channelId && <Link to="/">
                    <ResponsiveImage className={styles.logo}
                                     alt="Renew You"
                                     basePath="/images"
                                     src="renewyou-logo"/>
                </Link>}
                {channelId && <Link to={headerLink}>
                    <img className={headerLogoStyles.headerLogo} alt={`${channelId} Logo `}
                         src={`/course-info/${channelId}/channel-logo-light.png`}/>
                </Link>}
                {headerText && <h1 className={styles.headerText}>{headerText}</h1>}
            </nav>

            <LoginButton/>
        </header>
        <main><Outlet/></main>
        <Footer/>
    </>
};

interface RenewYouLayoutProps {
    mainStyle?: React.CSSProperties;
}

export const RenewYouLayout: React.FC<RenewYouLayoutProps> = ({mainStyle}) => {
    return (
        <>
            <header className={styles.renewYouHeader}>
                <Link to="/">
                    <ResponsiveImage
                        className={styles.renewYouLogo}
                        alt="RenewYou Logo"
                        basePath="/images"
                        src="renewyou-logo"
                    />
                </Link>
                <LoginButton/>
            </header>
            <main style={mainStyle}>
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
};