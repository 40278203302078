import React, { createContext, useContext, ReactNode } from 'react';
import { useUserAuth } from '~/hooks/use-user-auth';

interface User {
    id: string;
    name: string;
    email: string;
    firstName: string;
    lastName: string;
    // Add other user properties as needed
}

interface AuthContextProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    user: User | null;
    login: () => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const { data, isLoading, isError } = useUserAuth();
    const user = data?.user ?? null;
    const isAuthenticated = !!user && !isError;

    const login = () => {
        window.location.href = '/login';
    };

    const logout = async () => {
        await fetch(`${import.meta.env.VITE_API_HOST}/auth/logout/`, {
            method: "post",
            credentials: 'include'
        });
        window.location.href = "/login";
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};