import { useMutation } from 'react-query';
import { useState } from 'react';

interface ContactFormData {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    message: string;
}

const sendContactForm = async (formData: ContactFormData): Promise<any> => {
    const response = await fetch('/api/public/contact/contact-us', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        throw new Error('Failed to send message');
    }

    return response.json();
};

export const useContactForm = () => {
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const mutation = useMutation(sendContactForm, {
        onSuccess: () => {
            setMessage('Message sent successfully!');
            setError(null);
        },
        onError: (error: Error) => {
            setMessage(null);
            setError(error.message);
        },
    });

    return { mutation, message, error };
};
