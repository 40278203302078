import { useQuery, UseQueryResult } from 'react-query';
import Courses from '~/types/course';
import { useParams } from 'react-router-dom';

const fetchAuthorizedCourses = async (channelId: string): Promise<Courses[]> => {
    const response = await fetch(`/api/authenticated/courses/authorized-courses/${channelId}`, { credentials: 'include' });
    if (!response.ok) {
        throw new Error('Failed to fetch courses');
    }

    return await response.json();
};

export const useAuthorizedCourses = (): UseQueryResult<Courses[]> => {
    const { channelId } = useParams<{ channelId: string }>();

    return useQuery<Courses[]>(
        ['authorizedCourses', channelId],
        () => {
            if (channelId) {
                return fetchAuthorizedCourses(channelId);
            } else {
                return Promise.reject(new Error('Channel ID is undefined'));
            }
        },
        {
            enabled: !!channelId, // Only run the query if channelId is defined
        }
    );
};