import { useQuery } from 'react-query';
import axios from 'axios';

interface DiscountPriceResponse {
    discountCode: string;
    discountAmount: number | null;
    discountPercent: number | null;
    currency: string | null;
}

const fetchDiscountPrice = async (discountCode: string): Promise<DiscountPriceResponse> => {
    const response = await axios.post('/api/public/checkout/discount-price', { discountCode });
    return response.data;
};

const useDiscountPrice = (discountCode: string) => {
    return useQuery<DiscountPriceResponse, Error>(
        ['discountPrice', discountCode],
        () => fetchDiscountPrice(discountCode),
        {
            enabled: !!discountCode, // Only run the query if discountCode is provided
        }
    );
};

export default useDiscountPrice;